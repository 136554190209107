exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-hinnad-js": () => import("./../../../src/pages/hinnad.js" /* webpackChunkName: "component---src-pages-hinnad-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-masinad-js": () => import("./../../../src/pages/masinad.js" /* webpackChunkName: "component---src-pages-masinad-js" */),
  "component---src-pages-meist-js": () => import("./../../../src/pages/meist.js" /* webpackChunkName: "component---src-pages-meist-js" */),
  "component---src-pages-privaatsuspoliitika-js": () => import("./../../../src/pages/privaatsuspoliitika.js" /* webpackChunkName: "component---src-pages-privaatsuspoliitika-js" */),
  "component---src-pages-renditingimused-js": () => import("./../../../src/pages/renditingimused.js" /* webpackChunkName: "component---src-pages-renditingimused-js" */),
  "component---src-pages-teenused-js": () => import("./../../../src/pages/teenused.js" /* webpackChunkName: "component---src-pages-teenused-js" */),
  "component---src-templates-machine-js": () => import("./../../../src/templates/Machine.js" /* webpackChunkName: "component---src-templates-machine-js" */)
}

