import { getUnixTime } from 'date-fns';
import React, { createContext, useEffect, useState } from 'react';
import { API_URL } from './globals';
import { bookingFormStepOneSchema, bookingFormStepTwoSchema } from './formSchemas';

const FormContext = createContext();

const initialFormState = {
  bookings: [],
  rentalObject: '',
  rentType: '',
  attachmentIds: [],
  location: '',
  address: '',
  dates: [],
  customerName: '',
  customerCompany: '',
  customerPhone: '',
  customerEmail: '',
  customerAddress: '',
  remarks: '',
  termsAndConditions: false,
};

const FormProvider = ({ children }) => {
  const [errors, setErrors] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchError, setIsFetchError] = useState(false);
  const [formData, setFormData] = useState(initialFormState);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}api/booking?machine=${formData.rentalObject}`);
        const bookings = await response.json();
        setFormData((prevState) => ({
          ...prevState,
          bookings,
        }));
      } catch (error) {
        console.log(error);
      }
    };

    if (formData.rentalObject) {
      fetchData();
    }
  }, [formData.rentalObject]);

  useEffect(() => {
    setFormData((prevState) => ({
      ...initialFormState,
      rentType: prevState.rentType,
      rentalObject: prevState.rentalObject,
      bookings: prevState.bookings,
    }));
  }, [formData.rentType]);

  const resetForm = () => {
    setErrors(null);
    setActiveStep(0);
    setFormData(() => ({ ...initialFormState, dates: [] }));
    setIsFetchError(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const fullFormSchema = bookingFormStepOneSchema.concat(bookingFormStepTwoSchema);

    if (activeStep === 0) {
      const isStepOneValid = await bookingFormStepOneSchema.isValid(formData, {
        abortEarly: false,
      });

      if (isStepOneValid) {
        setActiveStep(activeStep + 1);
        setErrors(null);
      } else {
        bookingFormStepOneSchema.validate(formData, { abortEarly: false }).catch((err) => {
          const errors = err.inner.reduce((acc, error) => {
            return {
              ...acc,
              [error.path]: error.errors[0],
            };
          }, {});
          setErrors(errors);
        });
      }
    }

    if (activeStep === 1) {
      const isWholeFormValid = await fullFormSchema.isValid(formData, { abortEarly: false });

      if (isWholeFormValid) {
        setIsLoading(true);
        setIsFetchError(false);

        const unixDates = formData.dates.map((date) => getUnixTime(date));

        fetch(`${API_URL}api/booking/addBooking`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...formData, dates: unixDates }),
        })
          .then((resp) => resp.json())
          .catch((error) => {
            console.log(error);
            setIsFetchError(true);
          })
          .finally(() => {
            setActiveStep(activeStep + 1);
            setErrors(null);
            setIsLoading(false);
          });
      } else {
        fullFormSchema.validate(formData, { abortEarly: false }).catch((err) => {
          const errors = err.inner.reduce((acc, error) => {
            return {
              ...acc,
              [error.path]: true,
            };
          }, {});
          console.log('errors', errors);
          setErrors(errors);
        });
      }
    }
  };

  const value = {
    formData,
    activeStep,
    setFormData,
    setActiveStep,
    errors,
    handleSubmit,
    resetForm,
    isLoading,
    isFetchError,
  };

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

export { FormContext, FormProvider };
