import React from 'react';
import Nav from '../Nav';
import Footer from '../Footer';
import './styles.scss';

export default function Layout({ children }) {
  return (
    <div className='content'>
      <Nav />
      {children}
      <Footer />
    </div>
  );
}
