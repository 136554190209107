import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import './styles.scss';
import { Container } from 'react-bootstrap';

export default function Nav() {
  return (
    <Container>
      <nav>
        <ul>
          <li className='logo-item'>
            <Link to='/'>
              <StaticImage
                src='../../images/logo.png'
                alt='Rendimasinad'
                placeholder='blurred'
                objectFit='fit'
                width={300}
              />
              <p className='fs-3'>Kiire ja mugav masinate rent</p>
            </Link>
          </li>
          <li>
            <Link to='/teenused'>Teenused</Link>
          </li>
          <li>
            <Link to='/hinnad'>Hinnad</Link>
          </li>
          <li>
            <Link to='/masinad'>Masinad</Link>
          </li>
          <li>
            <Link to='/meist'>Meist</Link>
          </li>
          <li>
            <Link to='/kontakt'>Kontakt</Link>
          </li>
        </ul>
      </nav>
    </Container>
  );
}
