import './src/styles/styles.scss';
import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';
import Layout from './src/components/Layout';
import { ToastProvider } from './src/utils/toastContext';
import { FormProvider } from './src/utils/formContext';

export function wrapPageElement({ element, props }) {
  return (
    <Layout {...props}>
      <ToastProvider>
        <FormProvider>{element}</FormProvider>
      </ToastProvider>
    </Layout>
  );
}
