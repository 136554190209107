import { differenceInDays } from 'date-fns';

export const dateDiff = (dates = []) => {
  if (!dates.length) return;
  const diffInMs = new Date(dates[1]) - new Date(dates[0]);

  if (isNaN(diffInMs)) {
    return;
  }

  return differenceInDays(dates[1], dates[0]);
};

export const clone = (items) => items.map((item) => (Array.isArray(item) ? clone(item) : item));

export const getBookedDates = (dates = []) => {
  if (!dates.length) return [];
  const bookedDates = clone(dates);

  return bookedDates.flat().map((date) => date.split(' ')[0]);
};

// expected date format = "dd.MM.yyyy HH:mm"
export const getDateValues = (date) => {
  const [day, month, year] = date.split(' ')[0].split('.');
  const [hour, minute] = date.split(' ')[1].split(':');

  return {
    day,
    month,
    year,
    hour,
    minute,
  };
};
