import { Link } from 'gatsby';
import React from 'react';
import { Container } from 'react-bootstrap';
import instagramLogo from '../../images/instagram.png';
import facebookLogo from '../../images/facebook.png';
import './styles.scss';

export default function Footer() {
  return (
    <Container as='footer' className='mb-4 mt-5 text-center'>
      <ul>
        <li>
          <Link to='/renditingimused'>Renditingimused</Link>
        </li>
        <li>
          <Link to='/privaatsuspoliitika'>Privaatsuspoliitika</Link>
        </li>
        <li>
          <div className='d-inline-block align-middle'>
            <a
              href='https://www.instagram.com/rendimasinad/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <div className='d-flex align-items-center justify-content-center'>
                <img src={instagramLogo} alt='Instagram' /> <span className='ms-2'>Instagram</span>
              </div>
            </a>
          </div>
        </li>
        <li>
          <div className='d-inline-block align-middle'>
            <a
              href='https://www.facebook.com/Rendimasinad'
              target='_blank'
              rel='noopener noreferrer'
            >
              <div className='d-flex align-items-center justify-content-center'>
                <img src={facebookLogo} alt='Facebook' /> <span className='ms-2'>Facebook</span>
              </div>
            </a>
          </div>
        </li>
      </ul>
      <strong className='opacity-50 mt-4'>Rendimasinad &#169; {new Date().getFullYear()}</strong>
    </Container>
  );
}
