import React, { createContext, useState } from 'react';

const ToastContext = createContext();

const ToastProvider = ({ children }) => {
  const [type, setType] = useState('success');
  const [isVisible, setIsVisible] = useState(false);
  const [text, setText] = useState('E-mail saadetud!');

  const value = {
    type,
    text,
    isVisible,
    setType,
    setText,
    setIsVisible,
  };

  return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>;
};

export { ToastContext, ToastProvider };
