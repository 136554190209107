export const API_URL =
  process.env.NODE_ENV === 'development' ? '/' : 'https://api.rendimasinad.ee/';

export const descriptions = {
  teenused: {
    description:
      'Meie kogenud meeskond pakub laias valikus teenuseid. Aitame teid erinevate maastikuehitus- ja hooldustööde teostamisel, alustades pinnase- ja kaevetöödest ning lõpetades teede ja metsa hoolduse või lumekoristusega. Pakume ka erinevaid transporditeenuseid. Toome siin lehel välja levinumad teenused, kuid kui soovitud teenust nimekirjast ei leia, võta julgelt ühendust ning proovime leida teie soovile lahenduse. Teenuste broneerimiseks palume ühendust võtta, et saaksime koos planeerida töö detaile ning kuluvat aega.',
    metaDescription:
      'Meie kogenud meeskond pakub laias valikus teenuseid. Aitame teid erinevate maastikuehitus- ja hooldustööde teostamisel, nagu näiteks haljastus, kaevetööd, teede ja platside harjamine jne.',
  },
  hinnad: {
    description:
      'Masinate rent ilma juhita toimub 24 tunni arvestusega. Kõikidel nädalapäevadel kehtib sama hind ning mootori töötundide arvestust meie masinatega ei kaasne. Masina rent koos meie kogenud juhiga toimub tunnihinna alusel. Ajaarvestust alustame objektile jõudes ning lõpetame objektilt lahkudes. Minimaalne tööaja arvestus on kolm töötundi. Transpordi hindadega on võimalik tutvuda meie broneeringusüsteemis. Pikaajalise rendi hinnakiri rakendub alates 30-ndast rendipäevast.',
    metaDescription:
      'Masinate rent ilma juhita toimub ööpäeva arvestusega. Kõikidel nädalapäevadel kehtib sama hind. Teenus masina ja meie kogenud juhiga toimub tunnihinna alusel.',
  },
  masinad: {
    description:
      'Meie rendivalikust leiab erinevaid masinaid ja töövahendeid, mida saab rentida juhiga kui ka ilma. Teatud masinaid saab rentida ainult koos kogenud opetaatoriga. Infot masinapargi rentimise kohta leiab nii broneeringusüsteemist kui ka hinnakirjast. Juhita rendi puhul palume kindlasti eelnevalt tutvuda soovitud masina kasutus- ja ohutusjuhendiga ning muidugi ka meie renditingimustega.',
    metaDescription:
      'Meilt leiab erinevaid masinaid, mida saab rentida juhiga kui ka ilma, nagu nt Avant laadur ja halumasin. Teatud masinaid saab rentida ainult koos meie juhiga, nagu nt ekskavaator ja väikekallur.',
  },
  meist:
    'Oleme masinate teenust ja renti pakkuv ettevõte. Kogu meie masinaparki on võimalik rentida koos kogenud juhiga ning mitmeid masinaid ka ilma juhita. Võta julgelt meiega ühendust!',
  kontakt:
    'Kirjuta julgelt meiliaadressile info@rendimasinad.ee, helista +372 5350 90 90 või broneeri sobiv masin meie kodulehel.',
};
