import * as yup from 'yup';
import { areIntervalsOverlapping } from 'date-fns';
import { getDateValues } from '.';

export const contactFormSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string().required(),
  subject: yup.string().required(),
  content: yup.string().required(),
});

const selectValueShape = yup.object({
  label: yup.string(),
  value: yup.string(),
});

const transportMachines = ['vaeikeveok-mercedes-benz-sprinter', 'vaeikekallur-renault-master'];

export const bookingFormStepOneSchema = yup.object().shape({
  rentalObject: yup.string().required(),
  rentType: yup.object().nullable().required('Väli on nõutud'),
  attachmentIds: yup.array().of(selectValueShape),
  location: yup
    .object()
    .nullable()
    .when('rentalObject', {
      is: (rentalObject) => !transportMachines.includes(rentalObject),
      then: yup.object().nullable().required('Väli on nõutud'),
    }),
  address: yup.string().when(['location', 'rentalObject'], {
    is: (location, rentalObject) =>
      transportMachines.includes(rentalObject)
        ? false
        : location?.value === 'pickup'
        ? false
        : true,
    then: yup.string().required('Väli on nõutud'),
  }),
  dates: yup
    .array()
    .length(2, 'Väli on nõutud')
    .test(
      'overlapping-dates',
      'Kõikidel valitud kuupäevadel ei ole masin saadaval',
      function (value) {
        if (this.parent.bookings.length && value.length === 2) {
          const bookings = this.parent.bookings;

          for (const booking of bookings) {
            const bookingsStart = getDateValues(booking[0]);
            const bookingsEnd = getDateValues(booking[booking.length - 1]);
            const areOverlapping = areIntervalsOverlapping(
              { start: new Date(value[0]), end: new Date(value[1]) },
              {
                start: new Date(bookingsStart.year, bookingsStart.month - 1, bookingsStart.day),
                end: new Date(bookingsEnd.year, bookingsEnd.month - 1, bookingsEnd.day),
              }
            );

            if (areOverlapping) return false;
          }
        }
        return true;
      }
    )
    .required('Väli on nõutud'),
});

export const bookingFormStepTwoSchema = yup.object().shape({
  customerName: yup.string().required(),
  customerCompany: yup.string(),
  customerPhone: yup.string().required(),
  customerEmail: yup.string().email().required(),
  customerAddress: yup.string().required(),
  remarks: yup.string(),
  termsAndConditions: yup.bool().oneOf([true], 'Väli on nõutud'),
});
